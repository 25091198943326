import React from 'react';
import { PageWithSubNav, Paragraph } from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="January 2020"
      subnav="release-notes">
      <div id="January2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          We're in the throes of Uniform Version 4. Lucky for us (just kidding,
          we did this on purpose) there weren't any major fixes required in the
          last four weeks. Maybe we'll have a different update in February!
        </Paragraph>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
